import React, { useEffect } from "react";
import Script from "next/script";
import useTranslation from "next-translate/useTranslation";
import { init, getPreferences, onPreferencesChanged } from "cookie-though";
import config from "cookie-though.json";

export default function CookieThough() {
  const { lang } = useTranslation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    const handlePreferences = ({ cookieOptions }) => {
      const consentSettings = {
        ad_storage: "denied",
        analytics_storage: "denied",
      };

      if (typeof window === "undefined") {
        return;
      }

      cookieOptions.forEach(({ id, isEnabled }) => {
        if (id === "google-analytics" && isEnabled) {
          consentSettings.analytics_storage = "granted";
          gtag("send", "pageview");
        }

        gtag("consent", "update", consentSettings);
      });
    };

    init(config[lang]);
    handlePreferences(getPreferences());
    onPreferencesChanged(handlePreferences);
  }, [lang, init, getPreferences, onPreferencesChanged]);

  return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ? (
    <>
      <Script
        id="gtag"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
        strategy="afterInteractive"
      />
      <Script
        id="google-analytics"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){ window.dataLayer.push(arguments); }
              gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
              gtag('js', new Date());
              gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied"
              });
            `,
        }}
        strategy="afterInteractive"
      />
    </>
  ) : null;
}
